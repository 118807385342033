import React, { ReactNode } from 'react';
import { createContext, useContext, useState } from 'react';

const UserContext = createContext<any>({});
type Props = {
  children: ReactNode;
};
export const useUserContext = () => {
  return useContext(UserContext);
};

export const UserContextProvider = ({ children }: Props) => {
  const [userRole, setUserRole] = useState<string>('');
  const [username, setUsername] = useState<string>('');

  return (
    <UserContext.Provider
      value={{
        userRole,
        setUserRole,
        username,
        setUsername,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserContextConsumer = UserContext.Consumer;
