import { UserRole } from 'enums/user-roles';
import routes from 'router/routes';
import { isRouteAllowed } from 'router/utils';

const userLabelsMap = {
  [UserRole.ADMIN]: 'Admin',
  [UserRole.EDITOR]: 'Editor',
  [UserRole.CONTRIBUTOR]: 'Contributor',
};

const getItemsForUser = (role: UserRole) => {
  const menuItems = routes
    .filter(({ path }) => path !== '/')
    .filter(({ allowedUserRoles }) => isRouteAllowed(role, allowedUserRoles));
  const userRoleLabel = userLabelsMap[role];

  return { menuItems, userRoleLabel };
};

export { getItemsForUser };
