import React from 'react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcons from '../icons/MenuIcons';
import MenuIcon from '@mui/icons-material/Menu';
import CssBaseline from '@mui/material/CssBaseline';
import {
  AppBar,
  Box,
  Toolbar,
  Menu,
  MenuItem,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer,
  Typography,
  Chip,
  Avatar,
  IconButton,
} from '@mui/material';
import { RouteProps } from 'router/types';
import { getItemsForUser } from 'helpers/navbarHelper';
import { useUserContext } from 'context/UserContext';
import styles from './Navbar.module.scss';
import { amplifyConfig } from 'config/amplify-config';

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const { userRole, username } = useUserContext();

  const Auth = amplifyConfig();

  const { menuItems, userRoleLabel } = getItemsForUser(userRole);

  const stringAvatar = (name: string) => {
    return {
      children: `${name.split(' ')[0][0]}${
        name.split(' ')[1][0]
      }`.toUpperCase(),
    };
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNavigation = (page: string) => {
    navigate(page);
    setMobileOpen(false);
  };

  const logout = async () => {
    await Auth.signOut();
    navigate('/');
  };
  const drawer = (
    <>
      <Box id="drawer">
        <Toolbar disableGutters className={styles.websiteLogoToolbar}>
          <Link to="/titles">
            <MenuIcons.WebsiteIcon data-testid="imgMenuLogo" />
          </Link>
        </Toolbar>
        <Divider />
        <Typography
          textTransform="uppercase"
          variant="button"
          className={styles.menuSubHeader}
        >
          Manage
        </Typography>
        <List className="menutItemsList">
          {menuItems.map(({ title, path, icon: Icon }: RouteProps) => (
            <ListItem key={title} disablePadding>
              <ListItemButton
                data-testid={`buttonMenu${title}`}
                className={styles.customListItemButton}
                onClick={() => handleNavigation(path)}
              >
                <ListItemIcon className={styles.customListItemIcon}>
                  {Icon && <Icon />}
                </ListItemIcon>
                <ListItemText
                  primary={title}
                  className={styles.customListItemText}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
      <Box>
        <List id="userDetails" className={styles.userDetailsContainer}>
          <Chip
            className={styles.userRoleChip}
            variant="filled"
            label={userRoleLabel}
            data-testid="menuUserType"
          />
          <Button
            aria-haspopup="true"
            sx={{ color: 'white' }}
            id="userButton"
            onClick={handleClick}
          >
            <Avatar
              {...stringAvatar(username)}
              data-testid="buttonMenuUserAvatar"
            />
            <KeyboardArrowDownIcon data-testid="buttonMenuExpandArrow" />
          </Button>
          <Menu
            id="basic-menu"
            data-testid="buttonMenuExpandLogout"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={logout}>Logout</MenuItem>
          </Menu>
        </List>
      </Box>
    </>
  );

  return (
    <Box>
      <CssBaseline />
      <AppBar position="fixed" className={styles.customAppBar}>
        <Toolbar>
          <IconButton
            className={styles.toggleIconButton}
            color="inherit"
            id="menuToggleButton"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        aria-label="mailbox folders"
        id="drawersBox"
        className={styles.boxForDrawers}
      >
        <Drawer
          id="temporaryDrawer"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          variant="temporary"
          anchor="left"
          classes={{
            paper: styles.temporaryDrawerPaper,
          }}
          className={styles.temporaryDrawer}
        >
          {drawer}
        </Drawer>
        <Drawer
          id="permanentDrawer"
          variant="permanent"
          className={styles.permanentDrawer}
          classes={{
            paper: styles.permanentDrawerPaper,
          }}
          anchor="left"
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

export default Navbar;
