import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactComponent as ReportsSvgIcon } from './reports.svg';
import { ReactComponent as LanguagesSvgIcon } from './languages.svg';
import { ReactComponent as UsersSvgIcon } from './users.svg';
import { ReactComponent as AssetsSvgIcon } from './assets.svg';
import { ReactComponent as FormatsSvgIcon } from './formats.svg';
import { ReactComponent as TopicsSvgIcon } from './topics.svg';
import { ReactComponent as PathwaysSvgIcon } from './pathways.svg';
import { ReactComponent as CampaignsSvgIcon } from './campaigns.svg';
import { ReactComponent as TitlesSvgIcon } from './titles.svg';
import { ReactComponent as WebsiteSvgIcon } from './logo.svg';

const WebsiteIcon = (props: SvgIconProps) => (
  <SvgIcon component={WebsiteSvgIcon} inheritViewBox {...props} />
);
const ReportsIcon = (props: SvgIconProps) => (
  <SvgIcon component={ReportsSvgIcon} inheritViewBox {...props} />
);
const LanguagesIcon = (props: SvgIconProps) => (
  <SvgIcon component={LanguagesSvgIcon} inheritViewBox {...props} />
);

const AssetsIcon = (props: SvgIconProps) => (
  <SvgIcon component={AssetsSvgIcon} inheritViewBox {...props} />
);

const UsersIcon = (props: SvgIconProps) => (
  <SvgIcon component={UsersSvgIcon} inheritViewBox {...props} />
);

const TitlesIcon = (props: SvgIconProps) => (
  <SvgIcon component={TitlesSvgIcon} inheritViewBox {...props} />
);

const CampaignsIcon = (props: SvgIconProps) => (
  <SvgIcon component={CampaignsSvgIcon} inheritViewBox {...props} />
);

const PathwaysIcon = (props: SvgIconProps) => (
  <SvgIcon component={PathwaysSvgIcon} inheritViewBox {...props} />
);

const TopicsIcon = (props: SvgIconProps) => (
  <SvgIcon component={TopicsSvgIcon} inheritViewBox {...props} />
);

const FormatsIcon = (props: SvgIconProps) => (
  <SvgIcon component={FormatsSvgIcon} inheritViewBox {...props} />
);

const MenuIcons = {
  WebsiteIcon,
  TitlesIcon,
  CampaignsIcon,
  PathwaysIcon,
  TopicsIcon,
  FormatsIcon,
  AssetsIcon,
  UsersIcon,
  LanguagesIcon,
  ReportsIcon,
};

export default MenuIcons;
