import React from 'react';
import { Checkbox } from '@mui/material';

const CustomCheckbox = React.forwardRef<HTMLInputElement>((props, ref) => (
  <Checkbox inputRef={ref} color="secondary" {...props} />
));

CustomCheckbox.displayName = 'CustomCheckbox';

export default CustomCheckbox;
