import React from 'react';
import Typography from '@mui/material/Typography';
import { illustration } from 'components/images';
import styles from './ErrorBoundary.module.scss';

const ErrorFallback = () => {
  return (
    <div className={styles.errorPage}>
      <img src={illustration} alt="Something went wrong." />
      <Typography variant="h1">Something went wrong.</Typography>
      <Typography variant="body1" className={styles.errorText}>
        If the problem persists let us know.
      </Typography>
    </div>
  );
};

export default ErrorFallback;
