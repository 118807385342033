export const palette = {
  primary: {
    main: '#0F1733',
  },
  secondary: {
    main: '#FF5B31',
  },
  grey: {
    500: '#efeded',
  },
  warning: {
    main: '#FFA726',
  },
  error: {
    main: '#F44336',
    light: '#d32f2f',
  },
  success: {
    main: '#4CAF50',
  },
};
