import { baseTheme } from '../baseTheme';

const { typography } = baseTheme;

export default {
  styleOverrides: {
    root: {
      textTransform: 'none',
    },
    sizeMedium: {
      ...typography.h3,
      height: 42,
      fontSize: 15,
      paddingInline: 22,
    },
    sizeSmall: {
      ...typography.h5,
      height: 36,
      fontSize: 14,
      paddingInline: 16,
    },
  },
};
