export const typography = {
  fontFamily: 'Poppins',
  subtitle1: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '20px',
  },
  h1: {
    fontSize: '24px',
    fontWeight: '500',
    lineHeight: '32px',
  },
  h2: {
    fontSize: '16px',
    fontWeight: '600',
  },
  h3: {
    fontSize: '15px',
    fontWeight: '600',
  },
  h5: {
    fontSize: '14px',
    fontWeight: '600',
  },
};
