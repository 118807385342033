import React, { ReactNode, useEffect } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { axiosInstance } from 'config/axios';

type ErrorInterceptorProps = {
  children?: ReactNode;
};

const ErrorInterceptor = ({ children }: ErrorInterceptorProps) => {
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        const skipErrorHandler = error?.config?.skipErrorHandler;
        if (!skipErrorHandler) {
          showBoundary(error);
        }
        return Promise.reject(error);
      }
    );
  }, []);

  return <>{children}</>;
};

export default ErrorInterceptor;
