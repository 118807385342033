import React, { useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import { Button, Link } from '@mui/material';
import styles from './LoginForm.module.scss';
import { LoginError } from './LoginError';
import { useNavigate } from 'react-router';
import Loader from 'components/loader/Loader';

type LoginFormProps = {
  loginFailed: boolean;
  setForgotPassword: React.Dispatch<React.SetStateAction<boolean>>;
  setUser: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  retrieveTokenAndAuthenticateUser: (session: any) => void;
  setLoginFailed: React.Dispatch<React.SetStateAction<boolean>>;
  setChangePassword: React.Dispatch<React.SetStateAction<boolean>>;
  Auth: any;
};

export const LoginForm = ({
  loginFailed,
  setForgotPassword,
  Auth,
  setUser,
  retrieveTokenAndAuthenticateUser,
  setLoginFailed,
  setChangePassword,
}: LoginFormProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const history = useNavigate();

  const onUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setUsername(e.target.value);

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value);

  const login = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setIsLoading(true);

    Auth.signIn(username.toLowerCase(), password)
      .then((response: any) => {
        setIsLoading(false);
        setUser(response);

        if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setChangePassword(true);
          return;
        }
        retrieveTokenAndAuthenticateUser(response.signInUserSession);
        history('/');
      })
      .catch(() => {
        setLoginFailed(true);
        setIsLoading(false);
      });
  };
  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <Box className={styles.loginBoxContainer}>
          <Box className={styles.headerContainer}>
            <div>Welcome to</div>
            <div>Thrive Catalogue</div>
          </Box>
          <form className={styles.formContainer}>
            <Box className={styles.inputsContainer}>
              <Box className={styles.usernameContainer}>
                <InputLabel shrink htmlFor="email-input">
                  Email
                </InputLabel>
                <Input
                  className={styles.usernameInput}
                  autoComplete="on"
                  autoFocus={true}
                  fullWidth={true}
                  id="email-input"
                  onChange={onUsernameChange}
                  data-cy="login-email-input"
                />
              </Box>
              <Box className={styles.passwordContainer}>
                <Box className={styles.passwordLabelsContainer}>
                  <InputLabel shrink htmlFor="password-input">
                    Password
                  </InputLabel>
                  <Link
                    onClick={() => setForgotPassword(true)}
                    underline="none"
                    className={styles.forgotPassword}
                    color="secondary"
                    tabIndex={0}
                    component="button"
                    data-cy="forgot-password-button"
                  >
                    Forgot password?
                  </Link>
                </Box>
                <Input
                  type="password"
                  className={styles.passwordInput}
                  autoComplete="on"
                  fullWidth={true}
                  id="password-input"
                  onChange={onPasswordChange}
                  onKeyDown={(e) => e.key === 'Enter' && login(e)}
                  data-cy="login-password-input"
                ></Input>
              </Box>
              <LoginError
                error={loginFailed}
                errorMessage="Incorrect email or password"
              />
            </Box>
            <Button
              type="submit"
              color="secondary"
              fullWidth
              variant="contained"
              onClick={login}
              className={styles.submitButton}
              data-cy="sign-in-button"
            >
              Sign in
            </Button>
          </form>
        </Box>
      )}
    </>
  );
};
