import React from 'react';
import { Box, Typography } from '@mui/material';
import { illustration } from 'components/images';
import styles from './EmptyPageComponent.module.scss';

const EmptyPageComponent = () => {
  return (
    <div className={styles.emptyPageContainer}>
      <Box className={styles.emptyMessageBox}>
        <img
          tabIndex={0}
          src={illustration}
          id="empty-page-component"
          alt="There is no option/table"
          aria-label="empty-page-component"
          className={styles.emptyMessageImage}
        />
        <Typography variant="h2">
          There are no results to show at the moment.
        </Typography>
      </Box>
    </div>
  );
};

export default EmptyPageComponent;
