import React, { ReactNode } from 'react';
import { createContext, useContext, useState } from 'react';

//TODO Add types
const FileTypesContext = createContext<any>({});
type Props = {
  children: ReactNode;
};
export const useFileTypesContext = () => {
  return useContext(FileTypesContext);
};

export const FileTypesContextProvider = ({ children }: Props) => {
  const [queuedFileTypes, setQueuedFileTypes] = useState({});

  const value = { queuedFileTypes, setQueuedFileTypes };
  return (
    <FileTypesContext.Provider value={value}>
      {children}
    </FileTypesContext.Provider>
  );
};
