import React, { ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUserContext } from 'context/UserContext';
import Loader from 'components/loader/Loader';
import { RouteProps } from './types';
import { isRouteAllowed } from './utils';

const SecureRoute = ({ element, allowedUserRoles }: RouteProps) => {
  const { userRole } = useUserContext();

  if (!userRole) {
    return <Loader />;
  }
  if (!isRouteAllowed(userRole, allowedUserRoles)) {
    return <Navigate to="/" />;
  }
  return (element || <Outlet />) as ReactElement;
};

export default SecureRoute;
