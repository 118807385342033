import { baseTheme } from '../baseTheme';

const { palette } = baseTheme;

export default {
  styleOverrides: {
    root: {
      borderBottom: `1px solid ${palette.divider}`,
    },
  },
};
