import React from 'react';
import styles from './PasswordConfirmationForm.module.scss';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';

type PasswordConfirmationFormProps = {
  setNewPassword: React.Dispatch<React.SetStateAction<string>>;
  setConfirmNewPassword: React.Dispatch<React.SetStateAction<string>>;
};
export const PasswordConfirmationForm = ({
  setNewPassword,
  setConfirmNewPassword,
}: PasswordConfirmationFormProps) => {
  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setNewPassword(e.target.value);

  const onConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setConfirmNewPassword(e.target.value);

  return (
    <>
      <Box className={styles.newPasswordContainer}>
        <InputLabel shrink htmlFor="new-password-input">
          New password
        </InputLabel>
        <Input
          type="password"
          className={styles.newPasswordInput}
          autoComplete="on"
          fullWidth={true}
          id="new-password-input"
          onChange={onPasswordChange}
          data-cy="new-password-input"
        />
      </Box>
      <Box className={styles.confirmPasswordContainer}>
        <Box className={styles.passwordLabelsContainer}>
          <InputLabel shrink htmlFor="confirm-password-input">
            Confirm password
          </InputLabel>
        </Box>
        <Input
          type="password"
          className={styles.confirmPasswordInput}
          autoComplete="on"
          fullWidth={true}
          id="confirm-password-input"
          onChange={onConfirmPasswordChange}
          data-cy="new-password-confirmation-input"
        ></Input>
      </Box>
    </>
  );
};
