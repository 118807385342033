import { Components } from '@mui/material/styles';
import MuiButton from './button';
import MuiCard from './card';
import MuiCardActions from './cardActions';
import MuiCardContent from './cardContent';
import MuiCardMedia from './cardMedia';
import MuiChip from './chip';
import MuiContainer from './container';
import MuiDataGrid from './dataGrid';
import MuiDrawer from './drawer';
import MuiIconButton from './iconButton';
import MuiInputBase from './inputBase';
import MuiOutlinedInput from './outlinedInput';
import MuiSnackbar from './snackbar';
import MuiTab from './tab';
import MuiTabs from './tabs';
import MuiTablePagination from './tablePagination';
import MuiTextField from './textField';
import MuiPaper from './paper';

export const components = {
  MuiButton,
  MuiCard,
  MuiCardActions,
  MuiCardContent,
  MuiCardMedia,
  MuiChip,
  MuiContainer,
  MuiDataGrid,
  MuiDrawer,
  MuiIconButton,
  MuiInputBase,
  MuiOutlinedInput,
  MuiSnackbar,
  MuiTab,
  MuiTabs,
  MuiTablePagination,
  MuiTextField,
  MuiPaper,
} as Partial<Components>;
