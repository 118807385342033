import React from 'react';
import { Navigate } from 'react-router-dom';
import MenuIcons from 'components/icons/MenuIcons';
import { UserRole } from 'enums/user-roles';
import { RouteProps } from './types';

const TitlesView = React.lazy(() => import('views/TitlesView'));
const EditTitleView = React.lazy(() => import('views/EditTitleView'));
const NewTitleView = React.lazy(() => import('views/NewTitleView'));
const TopicsView = React.lazy(() => import('views/TopicsView'));
const CampaignsView = React.lazy(() => import('views/CampaignsView'));
const CreateCampaignView = React.lazy(() => import('views/CreateCampaignView'));
const EditCampaignView = React.lazy(() => import('views/EditCampaignView'));
const PathwaysView = React.lazy(() => import('views/PathwaysView'));
const CreatePathwayView = React.lazy(() => import('views/CreatePathwayView'));
const EditPathwayView = React.lazy(() => import('views/EditPathwayView'));
const UsersView = React.lazy(() => import('views/UsersView'));
const ReportsView = React.lazy(() => import('views/ReportsView'));

export const routes: RouteProps[] = [
  {
    path: '/',
    element: <Navigate to="/titles" />,
  },
  {
    path: '/titles',
    title: 'Titles',
    icon: MenuIcons.TitlesIcon,
    children: [
      {
        index: true,
        element: <TitlesView data-cy="titlePageLayout" />,
      },
      {
        path: ':id',
        element: <EditTitleView />,
      },
      {
        path: 'create',
        element: <NewTitleView />,
      },
    ],
  },
  {
    path: '/topics',
    title: 'Topics',
    element: <TopicsView />,
    icon: MenuIcons.TopicsIcon,
  },
  // will be re-introduced in phase 2 (CLUB-666)
  // {
  //   path: '/assets',
  //   title: 'Assets',
  //   element: <AssetsView />,
  //   icon: MenuIcons.AssetsIcon,
  //   allowedUserRoles: [UserRole.ADMIN],
  // },
  {
    path: '/campaigns',
    title: 'Campaigns',
    icon: MenuIcons.CampaignsIcon,
    children: [
      {
        index: true,
        element: <CampaignsView />,
      },
      {
        path: 'create',
        element: <CreateCampaignView />,
      },
      {
        path: ':id',
        element: <EditCampaignView />,
      },
    ],
  },
  {
    path: '/pathways',
    title: 'Pathways',
    icon: MenuIcons.PathwaysIcon,
    children: [
      {
        index: true,
        element: <PathwaysView />,
      },
      {
        path: 'create',
        element: <CreatePathwayView />,
      },
      {
        path: ':id',
        element: <EditPathwayView />,
      },
    ],
  },
  {
    path: '/users',
    title: 'Users',
    element: <UsersView />,
    icon: MenuIcons.UsersIcon,
    allowedUserRoles: [UserRole.ADMIN],
  },
  {
    path: '/reports',
    title: 'Reports',
    element: <ReportsView />,
    icon: MenuIcons.ReportsIcon,
    allowedUserRoles: [UserRole.ADMIN],
  },
];

export default routes;
