import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { FileTypesContextProvider } from 'context/SelectedFileTypesContext';
import { UserContextProvider } from 'context/UserContext';
import Layout from 'components/layout/Layout';
import LoginWrapper from 'components/authenticator/Authenticator';
import AuthInterceptor from 'components/authenticator/AuthInterceptor';
import Loader from 'components/loader/Loader';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';
import Router from 'router/Router';
import theme from 'theme';
import './App.scss';

function App() {
  return (
    <UserContextProvider>
      <FileTypesContextProvider>
        <BrowserRouter>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <AuthInterceptor>
                <LoginWrapper>
                  <Layout>
                    <ErrorBoundary>
                      <Suspense fallback={<Loader />}>
                        <Router />
                      </Suspense>
                    </ErrorBoundary>
                  </Layout>
                </LoginWrapper>
              </AuthInterceptor>
            </ThemeProvider>
          </StyledEngineProvider>
        </BrowserRouter>
      </FileTypesContextProvider>
    </UserContextProvider>
  );
}

export default App;
