import { baseTheme } from '../baseTheme';

const { palette } = baseTheme;

export default {
  styleOverrides: {
    paper: {
      background: palette.primary.main,
      color: palette.background.default,
    },
  },
};
