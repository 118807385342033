import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import styles from './PasswordUpdateForm.module.scss';
import { PasswordConfirmationForm } from './PasswordConfirmationForm';
import { LoginError } from './LoginError';
import Loader from 'components/loader/Loader';
import { PasswordLength } from 'enums/password-length';

type PasswordUpdateFormProps = {
  Auth: any;
  setChangePassword: any;
  signOut: () => void;
  user: Record<string, any>;
};

export const PasswordUpdateForm = ({
  user,
  Auth,
  setChangePassword,
  signOut,
}: PasswordUpdateFormProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
  const [errorMessage] = useState<string>(
    'Please include a minimimum of eight characters with 1 uppercase, 1 lowercase, 1 number and 1 special character.'
  );
  const [error, setError] = useState<boolean>(false);

  const submitNewPassword = () => {
    setIsLoading(true);

    Auth.completeNewPassword(user, newPassword)
      .then(() => {
        setChangePassword(false);
        signOut();
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => setIsLoading(false));
  };

  const confirmAndSubmitNewPassword = (
    event: React.FormEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    if (
      newPassword !== confirmNewPassword ||
      newPassword.length < PasswordLength.PASSWORD_MIN
    ) {
      return;
    }

    submitNewPassword();
  };

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <Box className={styles.loginBoxContainer}>
          <Box className={styles.headerContainer}>
            <div>Update your password</div>
          </Box>
          <Box className={styles.subheader}>
            <div>
              You need to update your password (password must be a minimimum of
              eight characters with 1 uppercase, 1 lowercase, 1 number and 1
              special character.)
            </div>
          </Box>
          <form className={styles.formContainer}>
            <Box>
              <PasswordConfirmationForm
                setNewPassword={setNewPassword}
                setConfirmNewPassword={setConfirmNewPassword}
              />
              <LoginError errorMessage={errorMessage} error={error} />
            </Box>
            <Button
              type="submit"
              color="secondary"
              fullWidth
              variant="contained"
              onClick={confirmAndSubmitNewPassword}
              className={styles.submitButton}
              disabled={
                newPassword !== confirmNewPassword ||
                newPassword.length < PasswordLength.PASSWORD_MIN
              }
              data-cy="submit-update-password"
            >
              Update password
            </Button>
          </form>
        </Box>
      )}
    </>
  );
};
