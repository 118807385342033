import React, { useEffect } from 'react';
import { amplifyConfig } from 'config/amplify-config';
import { axiosInstance } from 'config/axios';

interface AuthInterceptorProps {
  children: React.ReactNode;
}

const Auth = amplifyConfig();

const AuthInterceptor = ({ children }: AuthInterceptorProps) => {
  useEffect(() => {
    const requestInterceptor = (config: any): Promise<any> => {
      return new Promise((resolve, reject) => {
        Auth.currentSession().then((session: any) => {
          const validToken = session.isValid();

          if (validToken) {
            const token = session.getIdToken().getJwtToken();
            config.headers = { Authorization: `Bearer ${token}` };
            resolve(config);
          } else {
            reject();
          }
        });
      });
    };

    const errInterceptor = (error: any) => {
      return Promise.reject(error);
    };

    const interceptor = axiosInstance.interceptors.request.use(
      requestInterceptor,
      errInterceptor
    );

    return () => axiosInstance.interceptors.request.eject(interceptor);
  }, []);

  return <>{children}</>;
};

export default AuthInterceptor;
