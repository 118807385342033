import { baseTheme } from '../baseTheme';

const { breakpoints } = baseTheme;

export default {
  styleOverrides: {
    root: {
      height: '100%',
      paddingTop: 32,
      display: 'flex',
      flexDirection: 'column',
      [breakpoints.down('sm')]: {
        marginTop: '3rem',
      },
    },
  },
};
