import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useUserContext } from 'context/UserContext';
import styles from './Authenticator.module.scss';
import { LoginForm } from './LoginForm';
import Box from '@mui/material/Box';
import { PasswordUpdateForm } from './PasswordUpdateForm';
import { ResetPasswordForm } from './ForgotPassword/PasswordResetForm';
import { amplifyConfig } from '../../config/amplify-config';

const Auth = amplifyConfig();

type AuthenticatorProps = {
  children: React.ReactNode;
};

export default function LoginWrapper({ children }: AuthenticatorProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [loginFailed, setLoginFailed] = useState<boolean>(false);
  const [changePassword, setChangePassword] = useState<boolean>(false);
  const [forgotPassword, setForgotPassword] = useState<boolean>(false);
  const [authenticatedUser, setAuthenticatedUser] = useState<boolean>(false);
  const [user, setUser] = useState<Record<string, any>>({});
  const { setUserRole, setUsername } = useUserContext();

  const signOut = async () => {
    await Auth.signOut();
    navigate('/');
  };

  // https://github.com/aws-amplify/amplify-js/issues/4927
  const retrieveTokenAndAuthenticateUser = (session: any) => {
    setAuthenticatedUser(true);
    setUserRole(session.idToken.payload['cognito:groups'][0]);
    setUsername(
      `${session.idToken.payload.name} ${session.idToken.payload.family_name}`
    );
  };

  useEffect(() => {
    Auth.currentSession()
      .then((session) => {
        retrieveTokenAndAuthenticateUser(session);
      })
      .catch(() => {
        setAuthenticatedUser(false);
      });
  }, [pathname]);

  if (!authenticatedUser) {
    return (
      <>
        <Box className={styles.container}>
          {!changePassword && !forgotPassword && (
            <LoginForm
              loginFailed={loginFailed}
              setForgotPassword={setForgotPassword}
              Auth={Auth}
              setUser={setUser}
              retrieveTokenAndAuthenticateUser={
                retrieveTokenAndAuthenticateUser
              }
              setLoginFailed={setLoginFailed}
              setChangePassword={setChangePassword}
            />
          )}

          {changePassword && (
            <PasswordUpdateForm
              user={user}
              Auth={Auth}
              setChangePassword={setChangePassword}
              signOut={signOut}
            />
          )}

          {forgotPassword && (
            <ResetPasswordForm
              setForgotPassword={setForgotPassword}
              Auth={Auth}
              signOut={signOut}
            />
          )}
        </Box>
      </>
    );
  } else {
    return <React.Fragment>{children}</React.Fragment>;
  }
}
