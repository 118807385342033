import { Box } from '@mui/system';
import Loader from 'components/loader/Loader';
import React, { useState } from 'react';
import { ConfirmationCodeForm } from './ConfirmationCodeForm';
import styles from './PasswordResetForm.module.scss';
import { UsernameInputForm } from './UsernameInputForm';

type ResetPasswordFormProps = {
  setForgotPassword: React.Dispatch<React.SetStateAction<boolean>>;
  signOut: () => void;
  Auth: any;
};
export const ResetPasswordForm = ({
  setForgotPassword,
  signOut,
  Auth,
}: ResetPasswordFormProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const [showCodeAndNewPassword, setShowCodeAndNewPassword] =
    useState<boolean>(false);

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <Box className={styles.resetPasswordContainer}>
          {!showCodeAndNewPassword && (
            <UsernameInputForm
              setForgotPassword={setForgotPassword}
              setUsername={setUsername}
              username={username}
              Auth={Auth}
              setShowCodeAndNewPassword={setShowCodeAndNewPassword}
              setIsLoading={setIsLoading}
            />
          )}
          {showCodeAndNewPassword && (
            <ConfirmationCodeForm
              Auth={Auth}
              signOut={signOut}
              username={username}
              setForgotPassword={setForgotPassword}
              setIsLoading={setIsLoading}
              setShowCodeAndNewPassword={setShowCodeAndNewPassword}
            />
          )}
        </Box>
      )}
    </>
  );
};
