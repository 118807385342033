import { baseTheme } from '../baseTheme';

const { typography } = baseTheme;

export default {
  styleOverrides: {
    sizeSmall: {
      ...typography.subtitle2,
    },
  },
};
