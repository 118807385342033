export default {
  defaultProps: {
    size: 'small',
  },
  styleOverrides: {
    root: {
      lineHeight: '1.57em',
    },
    input: {
      height: '1.57em',
    },
  },
};
