import React, { useState } from 'react';
import { InputLabel, Input, Button } from '@mui/material';
import { Box } from '@mui/system';
import styles from './ConfirmationCodeForm.module.scss';
import { PasswordConfirmationForm } from '../PasswordConfirmationForm';
import { LoginError } from '../LoginError';
import { PasswordLength } from 'enums/password-length';

type ConfirmationCodeFormProps = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setForgotPassword: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCodeAndNewPassword: React.Dispatch<React.SetStateAction<boolean>>;
  signOut: () => void;
  username: string;
  Auth: any;
};

enum ErrorMessages {
  CodeMismatchException = 'Please enter the correct verification code.',
  Default = 'Please include a minimum of eight characters with 1 uppercase, 1 lowercase, 1 number and 1 special character.',
}

export const ConfirmationCodeForm = ({
  setForgotPassword,
  setShowCodeAndNewPassword,
  setIsLoading,
  username,
  signOut,
  Auth,
}: ConfirmationCodeFormProps) => {
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const onCodeChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCode(e.target.value);

  const confirmCodeAndNewPassword = () => {
    setIsLoading(true);
    setErrorMessage('');
    Auth.forgotPasswordSubmit(username, code, newPassword)
      .then(() => {
        setForgotPassword(false);
        signOut();
      })
      .catch((e: Error) => {
        setErrorMessage(
          ErrorMessages[e.name as keyof typeof ErrorMessages] ||
            ErrorMessages.Default
        );
      })
      .finally(setIsLoading(false));
  };

  const submitCodeAndPassword = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    confirmCodeAndNewPassword();
  };

  return (
    <>
      <Box className={styles.headerContainer}>
        <div>Reset your password</div>
      </Box>
      <Box className={styles.subheader}>
        <div>
          If there is an account associated with that email address, you will
          receive a verification code to help confirm your account and reset
          your password.
        </div>
      </Box>

      <form className={styles.formContainer}>
        <Box className={styles.inputsContainer}>
          <InputLabel shrink htmlFor="verification-code-input">
            Verification code
          </InputLabel>
          <Input
            type="text"
            className={styles.codeInput}
            autoComplete="on"
            autoFocus={true}
            fullWidth={true}
            id="verification-code-input"
            onChange={onCodeChange}
            data-cy="verification-code-input"
          />
          <PasswordConfirmationForm
            setNewPassword={setNewPassword}
            setConfirmNewPassword={setConfirmNewPassword}
          />
        </Box>
        <LoginError
          errorMessage={errorMessage}
          error={errorMessage.length > 0}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          onClick={submitCodeAndPassword}
          className={styles.submitButton}
          disabled={
            !code ||
            newPassword.length < PasswordLength.PASSWORD_MIN ||
            newPassword !== confirmNewPassword
          }
          data-cy="submit-new-password-and-code-button"
        >
          Submit
        </Button>
      </form>
      <Button
        onClick={() => setShowCodeAndNewPassword(false)}
        className={styles.returnToSignin}
        data-cy="resend-verification-code-button"
      >
        Didn&apos;t receive your code?
      </Button>
    </>
  );
};
