import React from 'react';
import styles from './LoginError.module.scss';

type LoginErrorProps = {
  error: boolean;
  errorMessage: string;
};
export const LoginError = ({ error, errorMessage }: LoginErrorProps) => {
  return (
    <>
      <p
        className={
          error
            ? styles.errorMessage
            : `${styles.errorMessage} ${styles.hidden}`
        }
      >
        {errorMessage}
      </p>
    </>
  );
};
