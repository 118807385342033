const ALERT_TIMEOUT = 5000;

export default {
  defaultProps: {
    autoHideDuration: ALERT_TIMEOUT,
  },
  styleOverrides: {
    root: {
      position: 'fixed',
    },
  },
};
