import EmptyPageComponent from 'components/emptyPageComponent/EmptyPageComponent';
import CustomCheckbox from 'components/customCheckbox/CustomCheckbox';

export default {
  defaultProps: {
    autoHeight: true,
    disableColumnMenu: true,
    disableSelectionOnClick: true,
    components: {
      NoRowsOverlay: EmptyPageComponent,
      BaseCheckbox: CustomCheckbox,
    },
  },
  styleOverrides: {
    root: {
      border: 'none',
      fontSize: 16,
    },
  },
};
