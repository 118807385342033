import { Auth } from 'aws-amplify';

const cognitoOptions = {
  region: process.env.REACT_APP_AWS_REGION,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
};

export const amplifyConfig = () => {
  Auth.configure({
    authenticationFlowType: process.env.REACT_APP_AUTH_FLOW,
    ...cognitoOptions,
  });
  return Auth;
};
