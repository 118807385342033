export default {
  styleOverrides: {
    root: {
      padding: 16,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
  },
};
