import React, { ReactNode } from 'react';
import { ErrorBoundary as ErrorBoundaryComponent } from 'react-error-boundary';

import ErrorInterceptor from './ErrorInterceptor';
import { useLocation } from 'react-router-dom';
import ErrorFallback from './ErrorFallback';

type ErrorBoundaryProps = {
  children?: ReactNode;
};

const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  const { pathname } = useLocation();

  return (
    <ErrorBoundaryComponent
      fallbackRender={ErrorFallback}
      resetKeys={[pathname]}
    >
      <ErrorInterceptor>{children}</ErrorInterceptor>
    </ErrorBoundaryComponent>
  );
};

export default ErrorBoundary;
