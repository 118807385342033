import React from 'react';
import { Box } from '@mui/material';
import Navbar from 'components/navbar/Navbar';

type LayoutProps = {
  children: React.ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  return (
    <Box display="flex">
      <Box>
        <Navbar />
      </Box>
      <Box flex={1}>{children}</Box>
    </Box>
  );
};

export default Layout;
