import React, { useMemo } from 'react';
import { useRoutes } from 'react-router-dom';
import { routes } from './routes';
import SecureRoute from './SecureRoute';

const Router = () => {
  const appRoutes = useMemo(
    () =>
      routes.map(({ element, ...rest }) => ({
        element: <SecureRoute element={element} {...rest} />,
        ...rest,
      })),
    []
  );

  return useRoutes(appRoutes);
};

export default Router;
