import React, { useState } from 'react';
import { InputLabel, Input, Button } from '@mui/material';
import { Box } from '@mui/system';
import styles from './UsernameInputForm.module.scss';

type UsernameInputFormProps = {
  setForgotPassword: React.Dispatch<React.SetStateAction<boolean>>;
  setUsername: React.Dispatch<React.SetStateAction<string>>;
  username: string;
  setShowCodeAndNewPassword: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  Auth: any;
};

export const UsernameInputForm = ({
  setForgotPassword,
  Auth,
  username,
  setUsername,
  setShowCodeAndNewPassword,
  setIsLoading,
}: UsernameInputFormProps) => {
  const [validEmail, setValidEmail] = useState<boolean>(false);

  const onUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailRegex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');
    setValidEmail(emailRegex.test(e.target.value));
    setUsername(e.target.value);
  };

  const sendConfirmationCodeToResetPassword = () => {
    setIsLoading(true);
    Auth.forgotPassword(username).then(() => {
      setIsLoading(false);
      setShowCodeAndNewPassword(true);
    });
  };

  return (
    <>
      <Box className={styles.headerContainer}>
        <div>Reset your password</div>
      </Box>
      <Box className={styles.subheader}>
        <div>
          Enter the email address associated with your account, and we&lsquo;ll
          send you a link to reset your password.
        </div>
      </Box>

      <form className={styles.formContainer}>
        <Box className={styles.inputsContainer}>
          <Box className={styles.usernameContainer}>
            <InputLabel shrink htmlFor="email-address-input">
              Email
            </InputLabel>
            <Input
              type="email"
              className={styles.usernameInput}
              autoComplete="on"
              autoFocus={true}
              fullWidth={true}
              id="email-address-input"
              onChange={onUsernameChange}
              data-cy="email-to-receive-verification-code-input"
            />
          </Box>
        </Box>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          onClick={sendConfirmationCodeToResetPassword}
          className={styles.submitButton}
          data-cy="send-verification-code-button"
          disabled={!validEmail}
        >
          Continue
        </Button>
      </form>
      <Button
        onClick={() => setForgotPassword(false)}
        className={styles.returnToSignin}
        data-cy="return-to-sign-in-button"
      >
        Return to sign in
      </Button>
    </>
  );
};
