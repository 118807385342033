import { baseTheme } from '../baseTheme';

const { palette } = baseTheme;

export default {
  styleOverrides: {
    root: {
      '& .Mui-disabled': {
        background: palette.grey[500],
      },
    },
  },
};
