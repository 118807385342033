import { baseTheme } from '../baseTheme';

const { palette, typography } = baseTheme;

export default {
  styleOverrides: {
    root: {
      ...typography.subtitle2,
    },
    colorInfo: {
      ...typography.subtitle2,
      border: `1px solid ${palette.text.disabled}`,
      color: palette.text.primary,
      height: 24,
      lineHeight: 18,
      cursor: 'pointer',
    },
  },
};
